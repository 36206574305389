<template>
  <div class="page-payment page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ progress.title }}</b></p>
      <p>{{ progress.description }}</p>
    </KlubbaRichText>

    <div class="button-wrap">
      <klubba-button
        v-if="icon"
        :customClass="customClass"
        :childClass="childClass"
        :icon="icon"
        :iconClass="iconClass"
        @click="stripePay"
        :loading="loading"
        theme="black"
      />
      <klubba-button
        :buttonText="`Pay $${price} AUD`"
        @click="stripePay"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import swal from 'sweetalert';
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import firebase from '@/config/firebase';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';

export default {
  components: {
    KlubbaRichText,
    KlubbaButton,
  },
  data() {
    return {
      payButtonPressed: false,
      loading: false,
      progress: {
        title: 'Finalise your payment',
        description: 'Choose an existing payment method or, create a new one.',
      },
      customClass: 'mr-7',
      childClass: 'flex items-center',
      iconClass: 'h-7',
    };
  },
  computed: {
    ...mapGetters([
      'club',
      'selectedClubSubscription',
      'platform',
      'selectedSubscriptionId',
      'billingInfo',
    ]),
    successURL() {
      return `${window.location.origin}/${this.$route.params.id}/account-review?paymentSuccess=true`;
    },
    cancelURL() {
      return `${window.location.origin}/${this.$route.params.id}/payment`;
    },
    icon() {
      if (this.platform === 'android') return 'google.svg';
      if (this.platform === 'ios') return 'apple.svg';
      return '';
    },
    price() {
      return this.selectedClubSubscription.price;
    },
  },
  methods: {
    ...mapMutations([
      'savePlatform',
    ]),
    async stripePay() {
      const subscriptionData = {
        successURL: this.successURL,
        cancelURL: this.cancelURL,
        club: this.club.id,
        subscription: this.selectedSubscriptionId,
        dateStart: this.billingInfo.billing_date,
        cancelAtPeriodEnd: !this.billingInfo.renew,
      };

      console.log(subscriptionData);

      if (!this.$store.getters.userSubscription) {
        this.loading = true;
        const subscriptionCheckout = await firebase.functions().httpsCallable('subscriptionCheckout');
        await subscriptionCheckout(subscriptionData)
          .then((result) => {
            console.log(result);
            this.loading = false;
            if (result.data.url) {
              document.location.href = result.data.url;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            swal('Error', error.message, 'error');
          });
      } else {
        await this.$router.push(`/${this.club.id}/account-review`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.card-wrap
  @apply h-80 my-7 py-7 bg-white rounded-radius-10 px-4 overflow-y-scroll

.card-number
  @apply mt-7 mb-2 text-gray-dark text-sm font-semibold

.button-wrap
  @apply p-8 mt-auto flex
</style>
